(function ($, Drupal) {
  Drupal.behaviors.webformOnce = {
    attach: function(context, settings) {

      /////////////////
      // Disable button when sending the form

      $('body.node-type-webform').once('webform-once', function() {
        var $webform_form = $(this).find('form.webform-client-form');

        $webform_form.each(function (index, el) {
          (function () {
            var allowSubmit = true;
            $webform_form[index].onsubmit = function () {
              if (allowSubmit)
                allowSubmit = false;
              else
                return false;
            }
          })()
        });
      });

      /////////////////
    }
  }
})(jQuery, Drupal);
